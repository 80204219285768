var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":_vm.id,"cancel-variant":"outline-primary","ok-title":"Lưu","cancel-title":"Hủy bỏ","size":"xl","centered":"","no-close-on-backdrop":"","title":_vm.title},on:{"ok":function($event){return _vm.accept($event)},"hidden":_vm.resetData}},[_c('validation-observer',{ref:"rule"},[_c('b-form',[_c('h3',[_c('feather-icon',{staticClass:"text-body",attrs:{"icon":"InfoIcon","size":"18"}}),_vm._v(" Thông tin chung ")],1),_c('b-row',[_c('b-col',{attrs:{"lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Mã đơn vị"),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"code","rules":"required","custom-messages":{
                  required: 'Mã đơn vị là bắt buộc',
                }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.dataInput.code),callback:function ($$v) {_vm.$set(_vm.dataInput, "code", $$v)},expression:"dataInput.code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Tên phòng giáo dục"),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"fullName","rules":"required","custom-messages":{
                  required: 'Tên phòng giáo dục là bắt buộc',
                }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.dataInput.fullName),callback:function ($$v) {_vm.$set(_vm.dataInput, "fullName", $$v)},expression:"dataInput.fullName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"4"}},[_c('b-form-group',[_c('label',{attrs:{"for":"code"}},[_vm._v("Kiểu người dùng"),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"userTypeId","rules":"required","custom-messages":{required: 'Kiểu người dùng là bắt buộc'}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-select',{attrs:{"reduce":function (item) { return item.id; },"label":"name","options":_vm.arrayUserType || [],"placeholder":"Lựa chọn kiểu người dùng"},model:{value:(_vm.dataInput.userTypeId),callback:function ($$v) {_vm.$set(_vm.dataInput, "userTypeId", $$v)},expression:"dataInput.userTypeId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Tỉnh/Thành phố"),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"provinceId","rules":"required","custom-messages":{required: 'Tỉnh/Thành phố là bắt buộc'}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-select',{attrs:{"reduce":function (item) { return item.id; },"label":"name","options":_vm.arrayProvinces || [],"placeholder":"Lựa chọn tỉnh/thành phố"},on:{"input":_vm.getComboboxDistrict},model:{value:(_vm.dataInput.provinceId),callback:function ($$v) {_vm.$set(_vm.dataInput, "provinceId", $$v)},expression:"dataInput.provinceId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Quận/Huyện"),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"districtId","rules":"required","custom-messages":{required: 'Quận/Huyện là bắt buộc'}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-select',{attrs:{"reduce":function (item) { return item.id; },"label":"name","options":_vm.arrayDistrict || [],"placeholder":"Lựa chọn quận/huyện"},on:{"input":_vm.getComboboxWard},model:{value:(_vm.dataInput.districtId),callback:function ($$v) {_vm.$set(_vm.dataInput, "districtId", $$v)},expression:"dataInput.districtId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"4"}},[_c('b-form-group',[_c('label',{attrs:{"for":"code"}},[_vm._v("Xã/Phường"),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"wardId","rules":"required","custom-messages":{required: 'Xã phường là bắt buộc'}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-select',{attrs:{"reduce":function (item) { return item.id; },"label":"name","options":_vm.arrayWards || [],"placeholder":"Lựa chọn xã/phường"},model:{value:(_vm.dataInput.wardId),callback:function ($$v) {_vm.$set(_vm.dataInput, "wardId", $$v)},expression:"dataInput.wardId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Email"),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"email","rules":"required|email","custom-messages":{required: 'email là bắt buộc', email: 'email sai định dạng'}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.dataInput.email),callback:function ($$v) {_vm.$set(_vm.dataInput, "email", $$v)},expression:"dataInput.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Số điện thoại")]),_c('b-form-input',{model:{value:(_vm.dataInput.phoneNumber),callback:function ($$v) {_vm.$set(_vm.dataInput, "phoneNumber", $$v)},expression:"dataInput.phoneNumber"}})],1)],1),_c('b-col',{attrs:{"lg":"4"}},[_c('b-form-group',[_c('label',{attrs:{"for":"code"}},[_vm._v("Fax ")]),_c('b-form-input',{model:{value:(_vm.dataInput.fax),callback:function ($$v) {_vm.$set(_vm.dataInput, "fax", $$v)},expression:"dataInput.fax"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"lg":"4"}},[_c('b-form-group',[_c('label',{attrs:{"for":"code"}},[_vm._v("Địa chỉ")]),_c('b-form-input',{model:{value:(_vm.dataInput.address),callback:function ($$v) {_vm.$set(_vm.dataInput, "address", $$v)},expression:"dataInput.address"}})],1)],1)],1),(_vm.type==='add')?_c('div',[_c('h3',[_c('feather-icon',{staticClass:"text-body",attrs:{"icon":"LogInIcon","size":"18"}}),_vm._v(" Thông tin đăng nhập ")],1),_c('b-row',[_c('b-col',{attrs:{"lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Tên đăng nhập"),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"code","rules":"required","custom-messages":{
                    required: 'Tên đăng nhập là bắt buộc',
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.dataInput.userName),callback:function ($$v) {_vm.$set(_vm.dataInput, "userName", $$v)},expression:"dataInput.userName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2549816248)})],1)],1),_c('b-col',{attrs:{"lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Mật khẩu"),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"name","rules":"required","custom-messages":{
                    required: 'Mật khẩu là bắt buộc',
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.dataInput.password),callback:function ($$v) {_vm.$set(_vm.dataInput, "password", $$v)},expression:"dataInput.password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2151866641)})],1)],1)],1)],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }