<template>
  <b-modal
    :id="idModalReissuePassword"
    size="sm"
    title="Cấp lại mật khẩu"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    ok-title="Lưu"
    @ok="handleReissuePassword"
    @hidden="resetData"
  >
    <validation-observer ref="rule">
      <b-form class="content-form">
        <b-form-group>
          <label for="InputHelp">Mật khẩu mới</label>
          <validation-provider
            #default="{ errors }"
            name="newPassword"
            rules="required|min:6"
            :custom-messages="customPassword"
          >
            <b-form-input
              id="InputHelp"
              v-model="queryBody.newPassword"
              :state="errors.length > 0 ? false:null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
          <!-- </div> -->
        </b-form-group>
        <b-form-group>
          <label for="InputHelp">Nhập lại mật khẩu</label>
          <validation-provider
            #default="{ errors }"
            name="confirmNewPassword"
            rules="required"
            :custom-messages="customRepassword"
          >
            <b-form-input
              id="InputHelp"
              v-model="queryBody.confirmNewPassword"
              :state="errors.length > 0 ? false:null"
              @keyup="checkPassword"
            />
            <small class="text-danger">{{ errors[0] }}</small>
            <small
              v-if="showRepeatPass"
              class="text-danger"
            >Mật khẩu nhập lại không đúng</small>
          </validation-provider>
          <!-- </div> -->
        </b-form-group>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BModal, BFormInput, BForm,
} from 'bootstrap-vue'

import { required, email, min } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BModal,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BForm,
  },
  props: {
    idModalReissuePassword: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      required,
      email,
      min,
      queryBody: {
        confirmNewPassword: '',
        newPassword: '',
      },
      customPassword: {
        required: 'Mật khẩu là bắt buộc',
        min: 'Mật khẩu ít nhất 6 ký tự',
      },
      customRepassword: {
        required: 'Mật khẩu nhập lại là bắt buộc',
      },
      showRepeatPass: false,
    }
  },
  methods: {
    handleReissuePassword(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$emit('handleReissuePassword', this.queryBody, this.$refs.rule)
    },
    checkPassword() {
      if (this.queryBody.newPassword !== this.queryBody.confirmNewPassword) {
        this.showRepeatPass = true
      } else {
        this.showRepeatPass = false
      }
      if (this.queryBody.confirmNewPassword === '') {
        this.showRepeatPass = false
      }
    },
    resetData() {
      this.queryBody = {
        confirmNewPassword: '',
        newPassword: '',
      }
    },
  },
}
</script>

<style>

</style>
